import React from 'react';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'

import { Web3ModalProjectId } from './config';
const chains = [mainnet]

const { publicClient } = configureChains(chains, [w3mProvider({ projectId: Web3ModalProjectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId:Web3ModalProjectId, version: 1, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
<>
  <WagmiConfig config={wagmiConfig}>
         <App />
  </WagmiConfig>
  <Web3Modal projectId={Web3ModalProjectId} ethereumClient={ethereumClient} />
</>

);