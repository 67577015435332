import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { contractAddress } from './config';
import TokenSaleContractAbi from './contracts/TokenSale.json';
import { watchContractEvent, readContracts } from '@wagmi/core'

function SaleInfo(props) {
  const [saleInfo, setSaleInfo] = useState({
    cap: '',
    individualCap: '',
    rate: '',
    weiRaised: '',
    totalClaimed: '',
    startTime: '',
    saleClosed: ''
  });

  

  const { isMobile, isTablet, web3Ready } = props;

  const computeSaleState = (saleInfo, currentTime) => {
    if (saleInfo.saleClosed || saleInfo.weiRaised >= saleInfo.cap) {
      return <label className='info-item-unit'>Closed</label>;
    } else if (currentTime <  Number(saleInfo.startTime)) {
      const duration = Number(saleInfo.startTime) - currentTime;
      const days = Math.floor(duration / (24 * 60 * 60));
      const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((duration % (60 * 60)) / 60);
      return (
        <label>
          <span className='info-item-unit'>
          Opens in  
          </span> {days}D {hours}H {minutes}M
        </label>
      );
    } else {
      return <label className='info-item-unit'>Open</label>;
    }
  }

  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  const [saleState, setSaleState] = useState('')
  watchContractEvent(
    {
      address: contractAddress,
      abi: TokenSaleContractAbi,
      eventName: 'TokensPurchased',
    },
    (log) => fetchData(),
  )

  watchContractEvent(
    {
      address: contractAddress,
      abi: TokenSaleContractAbi,
      eventName: 'TokensClaimed',
    },
    (log) => fetchData(),
  )
  useEffect(() => {
    if (saleInfo.saleClosed !== '') {
      setSaleState(computeSaleState(saleInfo, currentTime))
    }
    watchContractEvent(
      {
        address: contractAddress,
        abi: TokenSaleContractAbi,
        eventName: 'TokensPurchased',
      },
      (log) => fetchData(),
    )
  
    watchContractEvent(
      {
        address: contractAddress,
        abi: TokenSaleContractAbi,
        eventName: 'TokensClaimed',
      },
      (log) => fetchData(),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleInfo])
  const roundUp = (value) => {
    if (value !== '') {
      let precision = Math.pow(10, 4)
      return (
          Math.ceil(Number(value) * precision) / precision
      )
    } 
    return ''
  }
  async function fetchData() {
      const tokenSaleContract = {
        address: contractAddress,
        abi: TokenSaleContractAbi,
        }
      const data = await readContracts({
        contracts: [
          {
            ...tokenSaleContract,
            functionName: 'hardCap'
          },
          {
            ...tokenSaleContract,
            functionName: 'walletCap'
          },
          {
            ...tokenSaleContract,
            functionName: 'rate'
          },
          {
            ...tokenSaleContract,
            functionName: 'totalRaised'
          },
          {
            ...tokenSaleContract,
            functionName: 'startTime'
          },
          {
            ...tokenSaleContract,
            functionName: 'saleClosed'
          },
          {
            ...tokenSaleContract,
            functionName: 'totalTokensClaimed'
          }
          ]
        }) 
      const cap = data[0].result !== undefined ? data[0]?.result : 0
      const individualCap = data[1].result !== undefined ? data[1]?.result : 0
      const rate = data[2]?.result !== undefined  ? data[2]?.result : 0
      const weiRaised = data[3].result !== undefined ? data[3]?.result : 0
      const startTime = data[4].result !== undefined ? data[4]?.result : 0
      const saleClosed = data[5].result !== undefined ? data[5]?.result : ''
      const totalClaimed = data[6].result !== undefined ? data[6]?.result : 0
  
      setSaleInfo({ cap, individualCap, rate, weiRaised, totalClaimed, startTime, saleClosed });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="sale-infor col-11 mx-auto">
      <div className="contain-header">
        <h2 className='title-contain'>SALE INFORMATION</h2>
      </div>
      <div className="contain-body">
        <div className="info-row row pb-0">
          <div className={`${isTablet || isMobile ? "border-0" : ""} info-item col`}>
            <label className='info-item-title'>Sale Cap:</label>
            <div className='info-item-contain'>
              {web3Ready && saleInfo.cap !== '' ? roundUp(Web3.utils.fromWei(String(saleInfo.cap), "ether")) : ''} ETH
            </div>
          </div>
          <div className={`${isTablet || isMobile ? "border-0" : ""} info-item col`}>
            <label className='info-item-title'>Total Raised:</label>
            <div className='info-item-contain'>
              {web3Ready && saleInfo.weiRaised !== '' ? roundUp(Web3.utils.fromWei(String(saleInfo.weiRaised), "ether")) : ''} ETH
            </div>
          </div>
          <div className={`${isTablet || isMobile ? "border-0" : ""} info-item col`}>
            <label className='info-item-title'>Rate:</label>
            <div>
              <label className='info-item-contain'>{roundUp(saleInfo.rate.toString())}</label>
              <span className='info-item-unit'> Tokens/ETH</span>
            </div>
          </div>
          <div className={`${isTablet || isMobile ? "border-0" : ""} info-item col`}>
            <label className='info-item-title'>Total Claimed:</label>
            <div className='info-item-contain'>
              {web3Ready && saleInfo.totalClaimed !== '' ? roundUp(Web3.utils.fromWei(String(saleInfo.totalClaimed)), "ether") : ''} FLUID
            </div>
          </div>
        </div>

        <div className='info-row pt-0'>
          <div className="info-item col">
            <label className='info-item-title'>Sale State:</label>
            <div className='info-item-contain'>
              {saleState}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaleInfo;
