import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { contractAddress } from './config';
import TokenSaleContractAbi from './contracts/TokenSale.json';
import { toast } from 'react-toastify';
import { readContracts , writeContract, waitForTransaction } from '@wagmi/core'

function UserInfo(props) {
	const {userAddress} = props
	const [userContribution, setUserContribution] = useState('');
	const [tokensOwed, setTokensOwed] = useState('');
	const [tokensClaimed, setTokensClaimed] = useState('');
	const [isWhitelisted, setIsWhitelisted] = useState('');
	const [saleClosed, setSaleClosed] = useState(false);
	const [PUBLIC_LIMIT, setIndividualCap] = useState('');
	const [startTime, setStartTime] = useState('');
	const [isPresaleWallet, setPresaleWallet] = useState(false)
	async function refreshUserData(addr) {
		try {

			const tokenSaleContract = {
				address: contractAddress,
				abi: TokenSaleContractAbi,
			  }
			const data = await readContracts({
				contracts: [
					{
						...tokenSaleContract,
					  functionName: 'contributions',
					  args: [addr]
					},
					{
						...tokenSaleContract,
					  functionName: 'tokensOwed',
					  args: [addr]
					},
					{
						...tokenSaleContract,
					  functionName: 'whitelisted',
					  args: [addr]
					},
					{
						...tokenSaleContract,
					  functionName: 'claimedTokens',
					  args: [addr]
					},
					{
						...tokenSaleContract,
						functionName: 'saleClosed'
					},
					{
						...tokenSaleContract,
						functionName: 'walletCap'
					},
					{
						...tokenSaleContract,
						functionName: 'startTime'
					},
					{
						...tokenSaleContract,
						functionName: 'presaleWallet',
						args: [addr]
					}
				  ]
			  }) 
			setUserContribution(data[0]?.result !== undefined ? data[0]?.result : 0);
			setTokensOwed(data[1]?.result !== undefined ? data[1]?.result : 0);
			setIsWhitelisted(data[2]?.result ? 'Yes' : 'No');
			setTokensClaimed(data[3]?.result !== undefined ? data[3]?.result : 0);
			setSaleClosed(data[4]?.result !== undefined ? data[4]?.result : 0);
			setIndividualCap(data[5]?.result !== undefined ? data[5]?.result : 0);
			setStartTime(data[6]?.result !== undefined ? data[6]?.result : 0);
			setPresaleWallet(data[7]?.result !== undefined ? data[7]?.result : 0)
		} catch (error) {
			console.log('error', error)
		}
	}
	const roundUp = (value) => {
		if (value !== '') {
		  let precision = Math.pow(10, 4)
		  return (
			  Math.ceil(Number(value) * precision) / precision
		  )
		} 
		return ''
	}
	const [ethAmount, setEthAmount] = useState(''); // For ETH input
	const [errorMsg, setErrorMsg] = useState(''); // New state variable for the error message

	useEffect(() => {
		if(userAddress) {
			refreshUserData(userAddress);
		}
	}, [userAddress]);

	// Modify the setEthAmount method
	const handleEthAmountChange = (e) => {
		const value = e.target.value;
		if (value) {
			const weiValue = new Web3.utils.BN(Web3.utils.toWei(value, "ether"));
			//const maxLimit = new Web3.utils.BN(PUBLIC_LIMIT);
			const remainingAmountWei = new Web3.utils.BN(PUBLIC_LIMIT).sub(new Web3.utils.BN(userContribution));
			if (weiValue.gt(remainingAmountWei)) {
				setErrorMsg(`Max contribution amount left is ${Web3.utils.fromWei(String(remainingAmountWei), "ether")} ETH`);
			} else if (weiValue.isZero() || weiValue.isNeg()) {
				setErrorMsg(`Contribution amount should be greater than 0`);
			} else {
				setErrorMsg(''); // Reset error message
			}
		}

		setEthAmount(value);
	};

	async function buyTokensHandler() {
		try {
			  const { hash } = await writeContract({
				address: contractAddress,
				abi: TokenSaleContractAbi,
				functionName: 'buyTokens',
				from: userAddress,
				value: Web3.utils.toWei(ethAmount, "ether")
			  })
			  await waitForTransaction({
				hash,
			  })
			toast.success('Transaction successful');
			await refreshUserData(userAddress);  // Refresh user data after transaction
		} catch (error) {
			console.error("Error during token purchase:", error);
			toast.error(error.message);
		}
	}

	function buyMaxTokensHandler() {
		const remainingAmountWei = new Web3.utils.BN(PUBLIC_LIMIT).sub(new Web3.utils.BN(userContribution));
		setEthAmount(Web3.utils.fromWei(String(remainingAmountWei), "ether"));
		setErrorMsg(''); // Clear any existing error message
	}


	async function claimTokensHandler() {
		try {
			const { hash } = await writeContract({
				address: contractAddress,
				abi: TokenSaleContractAbi,
				functionName: 'claimTokens',
				from: userAddress
			})
			await waitForTransaction({
				hash,
			})
			toast.success('Tokens claimed successfully');
			await refreshUserData(userAddress);  // Refresh user data after transaction
		} catch (error) {
			console.error("Error during token claiming:", error);
			toast.error('Failed to claim tokens');
		}
	}

	return (
		<div className="user-infor col-11 mx-auto mt-4">
			<div className="contain-header">
				<h2 className='title-contain'>USER INFORMATION</h2>
			</div>
			<div className="contain-body">
				<div className='pt-4 pb-4'>
					<p><strong>Address:</strong> {userAddress}</p>
					<p><strong>ETH Contributed:</strong> {userAddress  && userContribution !== '' ? roundUp(Web3.utils.fromWei(String(userContribution), "ether")) : ''} ETH</p>
					<p><strong>Individual Cap:</strong> {userAddress && PUBLIC_LIMIT !== '' ? roundUp(Web3.utils.fromWei(String(PUBLIC_LIMIT), "ether")) : ''} ETH</p>
					<p><strong>Tokens Owed:</strong> {userAddress && tokensOwed !== '' ? roundUp(Web3.utils.fromWei(String(tokensOwed), "ether")) : ''}</p>
					<p><strong>Tokens Claimed:</strong> {userAddress && tokensClaimed !== '' ? roundUp(Web3.utils.fromWei(String(tokensClaimed), "ether")) : ''} FLUID</p>
					<p><strong>Whitelisted:</strong> {isWhitelisted}</p>
					{saleClosed && tokensOwed > 0 ? <button className="btn btn-custom" onClick={claimTokensHandler}>Claim Tokens</button> : null}
					{!saleClosed && !isPresaleWallet && new Date() >= new Date(Number(startTime) * 1000) 
					&& new Web3.utils.BN(userContribution).lt(new Web3.utils.BN(PUBLIC_LIMIT)) 
					&& isWhitelisted === 'Yes'
						&& (
							<div className="mt-4">
								<div className="action-container">
									<div className="input-container">
										<input
											type="text"
											value={ethAmount}
											onChange={handleEthAmountChange}
											className="form-control input-box"
											placeholder="Enter ETH amount or select max"
										/>
										<span
											onClick={buyMaxTokensHandler}
											className="max-text"
										>
											Max
										</span>
									</div>
									{/* Display the error message */}
									{errorMsg && <p className="text-error">{errorMsg}</p>}
									{/* Disable the button if there's an error */}
									<button onClick={buyTokensHandler} className="btn btn-custom mt-4" disabled={!!errorMsg}>Contribute</button>
								</div>
							</div>
						)}
				</div>
			</div>
		</div>
	);
}

export default UserInfo;
