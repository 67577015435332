import React, { useState, useEffect } from 'react';
import './App.css';
import UserInfo from './UserInfo';
import SaleInfo from './SaleInfo';
import logo from './images/logo-fluid.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import iconBar from './images/icon-bar.png';
import { useWeb3Modal } from '@web3modal/react'
import { getAccount, watchAccount, watchNetwork, getNetwork, disconnect  } from '@wagmi/core'
import { defaultNetworkChainId, defaultNetworkName } from './config';
import { InjectedConnector, connect } from '@wagmi/core'

function App() {
  const [userAddress, setUserAddress] = useState('');
  const [account, setAccount] = useState('')
  const { open } = useWeb3Modal()
  // Define state variables to track the device type
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isDesktopSmall, setIsDesktopSmall] = useState(false);

  // Define state variable openMenu and functions related to it
  const [openMenu, setOpenMenu] = useState(false);

  // Determine if the device is considered small (mobile or tablet)
  const isSmallDevice = isMobile || isTablet;
  // Function to fetch Ethereum account information


  // useEffect is a hook used to run side effects in your component
  useEffect(() => {
    const isTablet = !!navigator.userAgent.match(/iPad/i);
    const isMobile = !!(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    );
    const isDesktop = !isTablet && !isMobile;
    setIsDesktop(isDesktop);
    setIsTablet(isTablet);
    setIsMobile(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to handle screen size changes and set appropriate state variables
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // Assuming that tablets have a width between 768px and 1024px
      if (screenWidth < 768) {
        setIsTablet(false);
        setIsMobile(true);
        isDesktop && setIsDesktopSmall(true);
      } else if (screenWidth > 1024) {
        setIsTablet(false);
        setIsMobile(false);
        isDesktop && setIsDesktopSmall(false);
      } else {
        setIsTablet(true);
        setIsMobile(false);
      }
    };

    const handleOrientationChange = () => {
      handleResize();
    };

    // Initial check on mount
    handleResize();

    // Add event listeners for resize and orientation change
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup: Remove event listeners on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet, isDesktop]);

  // Function to close the menu
  const onCloseOpenMenu = () => {
    setOpenMenu(false);
  };

  // Function to toggle the menu open/close
  // const onClickOpenMenu = () => {
  //   setOpenMenu(!openMenu);
  // };

  const handleSwitchNetwork = async(event)=> {
    try {
      await connect({
        chainId: defaultNetworkChainId,
        connector: new InjectedConnector(),
      })
		} catch (error) {
			console.log('error', error)
		}
  }

  watchNetwork(
    (network) => {
      let currentAccount = getAccount()
      let chainId = network?.chain?.id
      if (chainId && currentAccount.address && chainId !== defaultNetworkChainId) {
        setAccount({address : ''})
        setUserAddress('')
        toast.warning( CustomToastWithLink(), {
          toastId: 'account'
        });
        disconnect()
      } else {
        setAccount(currentAccount)
        let accountAddress = currentAccount.address
        setUserAddress(accountAddress
          ? `${accountAddress.slice(0, 6)}...${accountAddress.slice(-6)}`
          : '')
      }
    }
  )
	const CustomToastWithLink = () => {
    return (
		<div className='switch-network'>
			<p>Your wallet is not connected to {defaultNetworkName}.</p>
		  <a onClick={handleSwitchNetwork}>Switch to {defaultNetworkName}</a>
		</div>
    )
  }

watchAccount((account) => {
  const { chain } = getNetwork()
  if (account.address) {
    if (chain.id === defaultNetworkChainId) {
      setAccount(account)
      let accountAddress = account.address
      setUserAddress(accountAddress
        ? `${accountAddress.slice(0, 6)}...${accountAddress.slice(-6)}`
        : '')
    } else {
        setAccount({address : ''})
        setUserAddress('')
        toast.warning( CustomToastWithLink(), {
          toastId: 'account'
        });
        disconnect()
    }
  }
})
  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <div className={`${openMenu ? "menu" : "hidden-menu"}`}>
          <div className={`form-group ${isSmallDevice ? "col-10 offset-1" : "col-8 offset-2"}  row header-menu`}>
            <div className={`${isDesktopSmall ? "col-10" : isMobile ? "col-6" : "col-8"} d-flex flex-row`}>
              <img src={logo} alt="Fluid" className="App-logo" />
            </div>
            <div className={`${isDesktopSmall ? "col-2" : isMobile ? "col-6" : "col-4"} 
                menu-sticky-items text-right d-flex justify-content-center`}>
              <div className="col-10  d-flex justify-content-end  align-items-center">
                <button className="btn btn-primary App-connect">For Sale</button>
              </div>
              <div className={`${isDesktopSmall ? "" : "col-2"} text-end`}>
                <button className="close-menu" onClick={onCloseOpenMenu}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="content-menu">
            <div className="content-center">
              <div>
                <span className="content-hover">
                  <a href="#dashboard" className="text-content">Dashboard</a>
                </span>
              </div>
              <div>
                <span className="content-hover">
                  <a href="#exchanges" className="text-content">Exchanges</a>
                </span>
              </div>
              <div>
                <span className="content-hover">
                  <a href="#watchlist" className="text-content">Watchlist</a>
                </span>
              </div>
              <div>
                <span className="content-hover">
                  <a href="#marketplace" className="text-content">Marketplace</a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {isMobile || isTablet ?
          <>
            <img src={logo} alt="Fluid" className="App-logo" />
            {userAddress ? (
              <button className="btn btn-custom App-connect" onClick={open}>{userAddress}</button>
            ) : (
              <button className="btn btn-custom App-connect" onClick={open}>Connect Wallet</button>
            )}
            {/* <button className="burger-menu mb-1" onClick={onClickOpenMenu}>
              <img src={iconBar} alt="Fluid" className="iconBar" />
            </button> */}
          </>
          :
          <nav className={` navbar navbar-expand-lg navbar-light col-12`} >
            <img src={logo} alt="Fluid" className="App-logo" />
            <div className="collapse navbar-collapse" id="navbarNav">
              {/* <ul className="navbar-nav">
                <li className="nav-item active mt-1">
                  <a className="nav-link" href="#dashboard">Dashboard <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item mt-1">
                  <a className="nav-link" href="#exchanges">Exchanges</a>
                </li>
                <li className="nav-item mt-1">
                  <a className="nav-link" href="#watchlist">Watchlist</a>
                </li>
                <li className="nav-item mt-1">
                  <a className="nav-link" href="#marketplace">Marketplace</a>
                </li>
                <button className="btn btn-primary App-connect">For Sale</button>
              </ul> */}
            </div>
            {userAddress ? (
              <button className="btn btn-custom App-connect" onClick={open}>{userAddress}</button>
            ) : (
              <button className="btn btn-custom App-connect" onClick={()=> {
                open()
              }}>Connect Wallet</button>
            )}
          </nav>
        }
      </header>
      <main className="main">
        <SaleInfo
          isMobile={isMobile}
          isTablet={isTablet}
          web3Ready={account.address ? true : false}
        />
        <UserInfo 
         userAddress={account.address}
        />
      </main>
    </div>
  );
}

export default App;
